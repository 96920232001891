<template>
  <div v-if="item" class="drawer-wrapper">
    <v-list-item class="py-2 drawer-header">
      <v-icon @click="$emit('close')" class="me-4">fas fa-arrow-left</v-icon>
      <v-img v-if="item.company.logo_url" :src="item.company.logo_url" :max-width="100" :max-height="50" class="mr-5"></v-img>
      <v-list-item-content>
        <v-list-item-title>
          {{item.company.name}}
          <span class="text-xs">scanned at: {{toDate(item.check.createdAt)}}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="pl-5 pr-5 pt-5 tabs-wrapper">
      <v-tabs v-model="activeTab" icons-and-text class="articles-tabs">
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-summary">Summary</v-tab>

        <v-tab v-if="item.check.warnings.length" href="#tab-articles">
          <v-icon class="me-5 text-red">fas fa-exclamation-triangle</v-icon>
          <span>Low sentiment</span>
        </v-tab>

        <v-tab href="#tab-checks">All Checks</v-tab>
      </v-tabs>

      <v-tabs-items v-model="activeTab" class="scrollbar">
        <v-tab-item :value="'tab-summary'">
          <h4 class="font-weight-medium">Summary by <strong>sectors</strong></h4>
          <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
            <p class="text-secondary font-weight-bold text-xs mb-0">Last check changes</p>
            <v-row no-gutters>
              <v-col v-for="t in item.check.trends" sm="4">
                <p class="text-sm mb-0 text-capitalize font-weight-bold text-body">
                  <span class="me-1" style="display: inline-block; border-radius: 50%; width: 8px; height: 8px;" v-bind:style="{background: t.color}"></span>
                  {{t.name}}
                </p>
                <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                  <v-icon v-if="t.trend" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="mt-1">fas fa-arrow-{{ t.trend }} me-2</v-icon>
                  <span v-if="t.diff" v-bind:class="{'text-success': t.trend === 'up', 'text-danger': t.trend === 'down'}" class="text-sm font-weight-bolder">{{ t.diff }}%</span>
                  <span v-if="!t.diff" class="text-sm font-weight-bolder">{{ t.value }}%</span>
                </h5>
              </v-col>
            </v-row>
          </v-card>

          <esg-line-chart v-if="item.check.lineChart" :id="item.check.id" :labels="item.check.lineChart.labels" :datasets="item.check.lineChart.datasets" :show-card="true"></esg-line-chart>

          <template v-for="(value, key) in item.check.sectors">
            <h4 class="mt-10 font-weight-medium d-flex align-center">
                <span class="me-2" style="display: inline-block; border-radius: 50%; width: 10px; height: 10px;" :style="{background: value.color}"></span>
                <strong>{{value.name}}</strong>&nbsp;sector
            </h4>
            <v-card class="mb-6 card-shadow border-radius-xl px-4 py-4">
              <v-simple-table class="table border-radius-xl" fixed-header>
                <template v-slot:default>
                  <thead>
                  <tr>
                    <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7" width="60%">Sector</th>
                    <th class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">External (BBG) Data</th>
                    <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"></th>
                  </tr>
                  </thead>
                  <template v-for="(areaValue, areaKey) in item.check.sectors[key].groups">
                    <tbody>
                    <tr @click="toggleSubAreaTable(item.check.sectors, key, areaKey)" :key="areaKey">
                      <td class="text-sm">{{areaValue.name}}</td>
                      <td>
                        <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                          <v-icon v-if="areaValue.trend" v-bind:class="{'text-success': areaValue.trend === 'up', 'text-danger': areaValue.trend === 'down'}" class="mt-1">fas fa-arrow-{{ areaValue.trend }} me-2</v-icon>
                          <span v-if="areaValue.diff" v-bind:class="{'text-success': areaValue.trend === 'up', 'text-danger': areaValue.trend === 'down'}" class="text-sm font-weight-bolder">{{ areaValue.diff }}%</span>
                          <span v-if="!areaValue.diff" class="text-sm font-weight-bolder">{{ areaValue.resultInPercents.toFixed(2) }}%</span>
                        </h5>
                      </td>
                      <td class="text-right">
                        <div style="position: relative;">
                          <v-scroll-x-transition leave-absolute>
                            <a v-if="!areaValue.opened"><v-icon>fas fa-arrow-right</v-icon></a>
                          </v-scroll-x-transition>

                          <v-scroll-x-transition leave-absolute>
                            <a v-if="areaValue.opened"><v-icon>fas fa-arrow-down</v-icon></a>
                          </v-scroll-x-transition>
                        </div>
                      </td>
                    </tr>
                    </tbody>
                    <v-slide-y-transition>
                      <tbody v-if="areaValue.opened">
                      <tr v-for="(subAreaValue, subAreaKey) in item.check.sectors[key].groups[areaKey].groups" :key="subAreaKey">
                        <td><div class="pl-5 text-sm text-secondary">{{subAreaValue.name}}</div></td>
                        <td>
                          <h5 class="text-h5 text-typo font-weight-bolder mb-0">
                            <v-icon v-if="subAreaValue.trend" v-bind:class="{'text-success': subAreaValue.trend === 'up', 'text-danger': subAreaValue.trend === 'down'}" class="mt-1">fas fa-arrow-{{ subAreaValue.trend }} me-2</v-icon>
                            <span v-if="subAreaValue.diff" v-bind:class="{'text-success': subAreaValue.trend === 'up', 'text-danger': subAreaValue.trend === 'down'}" class="text-sm font-weight-bolder">{{ subAreaValue.diff }}%</span>
                            <span v-if="!subAreaValue.diff" class="text-sm font-weight-bolder">{{ subAreaValue.resultInPercents.toFixed(2) }}%</span>
                          </h5>
                        </td>
                        <td class="text-right">
                          <a v-if="subAreaValue.bings.length" @click="showArticlesBySubArea(item, key, areaKey, subAreaKey, subAreaValue.bings)" class="text-xs text-blue">
                            show articles
                            <v-icon class="ms-1 text-blue" x-small>fas fa-arrow-right</v-icon>
                          </a>
                          <span v-if="!subAreaValue.bings.length" class="text-disabled text-xs">no articles</span>
                        </td>
                      </tr>
                      </tbody>
                    </v-slide-y-transition>
                  </template>
                </template>
              </v-simple-table>
            </v-card>
          </template>
        </v-tab-item>

        <v-tab-item v-if="item.check.warnings.length" :value="'tab-articles'">
          <v-card flat>
            <span class="d-block text-body-2 mb-2 text-sm text-red font-weight-bold ">Articles with low sentiment score</span>
            <articles-table :articles="item.check.warnings"></articles-table>
          </v-card>
        </v-tab-item>

        <v-tab-item v-if="item.check.bings.length" :value="'tab-checks'">
          <v-card flat>
            <articles-table :articles="item.check.bings"></articles-table>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import ESGLineChart from '../../Widgets/Charts/LineChart.vue'
import ArticlesTable from '@/views/Pages/Feed/ArticlesTable'

export default {
  name: 'feed-check-detail',

  components: {
    'esg-line-chart': ESGLineChart,
    'articles-table': ArticlesTable
  },

  props: {
    item: Object,
    tab: String,
  },

  data() {
    return {
      activeTab: null
    }
  },

  created() {
    this.activeTab = this.tab

      console.log(this.item)
  },

  watch: {
    tab(tab) {
      this.activeTab = tab
    }
  },

  methods: {
    toDate(date) {
      return moment(date).format('ddd, D. MMMM, YYYY')
    },

    toggleSubAreaTable(item, sectorKey, subAreaKey) {
      const newState = !item[sectorKey].groups[subAreaKey].opened
      item[sectorKey].groups[subAreaKey] = {...item[sectorKey].groups[subAreaKey], opened: newState}
    },

    showArticlesBySubArea(item, key, areaKey, subAreaKey, bings) {
      const subArea = item.check.sectors[key].groups[areaKey].groups[subAreaKey]
      item.path = bings[0].path
      item.stringPath = bings[0].stringPath
      this.$emit('showBings', {check: item, bings})
    }
  }
}
</script>

<style>
.articles-tabs .v-tabs-slider-wrapper {
  height: 1px !important;
}

.articles-tabs .v-tabs-slider {
  background-color: #c2c2c2 !important;
}

.articles-tabs > .v-tabs-bar {
  height: 40px !important;
}

.articles-tabs .v-tab {
  flex-direction: row !important;
  align-items: center !important;
  text-transform: none !important;
  max-width: unset !important;
}

.articles-tabs .v-tab .v-icon {
  margin-bottom: unset !important;
}

.tabs-wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: visible;
  padding-bottom: 60px;
}

.tabs-wrapper .articles-tabs {
  flex: 0 0 60px;
}

.tabs-wrapper .v-tabs-items {
  flex: 1 1 auto;
  overflow-y: auto;
  margin-top: .5em;
}

</style>
