<template>
  <div v-if="check" class="wrapper">
    <v-list-item class="py-2 card-header">
      <v-icon @click="$emit('close')" class="me-4">fas fa-arrow-left</v-icon>
      <v-img v-if="check.company.logo_url" :src="check.company.logo_url" :max-width="100" :max-height="50" class="mr-5"></v-img>
      <v-list-item-content>
        <v-list-item-title>
          <span class="text-xs">{{check.stringPath}}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <div class="px-5 content scrollbar">
      <v-card flat>
        <articles-table :articles="bings"></articles-table>
      </v-card>
    </div>
  </div>
</template>
<script>
import moment from 'moment'
import ArticlesTable from '@/views/Pages/Feed/ArticlesTable'

export default {
  name: 'feed-check-articles',

  components: {
    'articles-table': ArticlesTable
  },

  props: {
    check: Object,
    bings: Array,
  },

  data() {
    return {

    }
  },

  methods: {
    toDate(date) {
      return moment(date).format('ddd, D. MMMM, YYYY')
    }
  }
}
</script>

<style>
.wrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: space-between;
}

.card-header {
  border-bottom: 1px solid rgb(229, 229, 229);
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9;
  background: #fff;
  flex: 0 0 60px;
}

.content {
  flex: 1 1 auto;
  overflow-y: auto;
  margin-top: 1rem;
}
</style>
